import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// import { DateTime } from 'luxon'

const BACKEND_API_URL = process.env.REACT_APP_AUTH0_AUDIENCE

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: navigator.language,
    load: 'languageOnly',  // This will ensure only the language part of the navigator.language is used
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      // format: (value, format, lng) => { // legacy usage
      //   if (value instanceof Date) {
      //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
      //   }
      //   return value;
      // }
    },
    backend: {
      loadPath: `${BACKEND_API_URL}/v3/locales/{{lng}}/{{ns}}.json`, //Todo Replace with baseUrl(`/locales/{{lng}}/{{ns}}.json`)
    },
    ns: "signup",
    defaultNS: "signup",
    react: {
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "b"],
    },
  });

// new usage
// i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {
//   return DateTime.fromJSDate(value)
//     .setLocale(lng)
//     .toLocaleString(DateTime.DATE_HUGE)
// })

export default i18n;
