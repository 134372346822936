import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const specialCharacters = [
  " ",
  "!",
  '"',
  "#",
  "$",
  "%",
  "&",
  "'",
  "(",
  ")",
  "*",
  "+",
  ",",
  "-",
  ".",
  "/",
  ":",
  ";",
  "<",
  "=",
  ">",
  "?",
  "@",
  "[",
  "]",
  "\\",
  "^",
  "_",
  "`",
  "{",
  "|",
  "}",
  "~",
];

const isEmptyCharacter = (c) => {
  return c === ''
}

const isSpecialCharacter = (c) => {
  return specialCharacters.includes(c)
}

const isNumericCharacter = (c) => {
  return !isEmptyCharacter(c) && !isSpecialCharacter(c) && !isNaN(c)
}

const isLowerCaseCharacter = (c) => {
  return !isEmptyCharacter(c) && !isNumericCharacter(c) && !isSpecialCharacter(c) && c == c.toLowerCase()
}

const isUpperCaseCharacter = (c) => {
  return !isEmptyCharacter(c) && !isNumericCharacter(c) && !isSpecialCharacter(c) && c == c.toUpperCase()
}

function PasswordChecker({ password, onIsValid }) {
  const [isEightCharacters, setIsEightCharacters] = useState(false);
  const [isThreeOfFourContainments, setIsThreeOfFourContainments] =
    useState(false);
  const [containsLowerCase, setContainsLowerCase] = useState(false);
  const [containsUpperCase, setContainsUpperCase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsSpecialCharacter, setContainsSpecialCharacter] =
    useState(false);

  const { t } = useTranslation()

  useEffect(() => {
    const fulfillsLowerCaseCondition = checkLowerCase(password);
    const fulfillsUpperCaseCondition = checkUpperCase(password);
    const fulfillsNumberCondition = checkNumber(password);
    const fulfillsSpecialCharacterCondition = checkSpecialCharacter(password);
    const fulfillsThreeConditions = checkThreeOfFourContainments(
      fulfillsLowerCaseCondition,
      fulfillsUpperCaseCondition,
      fulfillsNumberCondition,
      fulfillsSpecialCharacterCondition
    );
    const fulfillsEightCharacterCondition = checkEightCharacters(password);
    onIsValid(fulfillsEightCharacterCondition && fulfillsThreeConditions);
  }, [password, onIsValid]);

  const checkEightCharacters = (password) => {
    const fulfillsEightCharacterCondition = password.length >= 8;
    setIsEightCharacters(fulfillsEightCharacterCondition);
    return fulfillsEightCharacterCondition;
  };

  const checkThreeOfFourContainments = (
    fulfillsLowerCaseCondition,
    fulfillsUpperCaseCondition,
    fulfillsNumberCondition,
    fulfillsSpecialCharacterCondition
  ) => {
    const count =
      fulfillsLowerCaseCondition +
      fulfillsUpperCaseCondition +  
      fulfillsNumberCondition +
      fulfillsSpecialCharacterCondition;
    const fulfillsThreeConditions = count >= 3;
    setIsThreeOfFourContainments(fulfillsThreeConditions);
    return fulfillsThreeConditions;
  };

  const checkLowerCase = (password) => {
    let i = 0;
    let c = "";
    while (i < password.length) {
      c = password.charAt(i);
      if (isLowerCaseCharacter(c)) {
        setContainsLowerCase(true);
        return true;
      }
      i++;
    }
    setContainsLowerCase(false);
    return false;
  };

  const checkUpperCase = (password) => {
    let i = 0;
    let c = "";
    while (i < password.length) {
      c = password.charAt(i);
      if (isUpperCaseCharacter(c)) {
        setContainsUpperCase(true);
        return true;
      }
      i++;
    }
    setContainsUpperCase(false);
    return false;
  };

  const checkNumber = (password) => {
    let i = 0;
    let c = "";
    while (i < password.length) {
      c = password.charAt(i);
      if (isNumericCharacter(c)) {
        setContainsNumber(true);
        return true;
      }
      i++;
    }
    setContainsNumber(false);
    return false;
  };

  const checkSpecialCharacter = (password) => {
    let i = 0;
    let c = "";
    while (i < password.length) {
      c = password.charAt(i);
      if (isSpecialCharacter(c)) {
        setContainsSpecialCharacter(true);
        return true;
      }
      i++;
    }
    setContainsSpecialCharacter(false);
    return false;
  };

  return (
    <Fragment>
      {!!password && password.length > 0 && (
        <List dense>
          <ListItem>
            <ListItemText primary={t('signup:passwordRequirements')} />
          </ListItem>
          <ListItem sx={{ pl: 4 }}>
            <ListItemIcon>
              {isEightCharacters && (
                <CheckCircleIcon color="success" />
              )}
              {!isEightCharacters && (
                <CancelIcon color='error' />
              )}
            </ListItemIcon>
            <ListItemText primary={t('signup:atLeastEightCharacters')} />
          </ListItem>
          <ListItem sx={{ pl: 4 }}>
              <ListItemIcon>
                {isThreeOfFourContainments && (
                  <CheckCircleIcon color="success" />
                )}
                {!isThreeOfFourContainments && (
                  <CancelIcon color='error' />
                )}
              </ListItemIcon>
            <ListItemText primary={t('signup:atLeastThreeOfTheFollowing')} />
          </ListItem>
          <ListItem sx={{ pl: 8 }}>
            <ListItemIcon>
              {containsLowerCase && (
                <CheckCircleIcon color="success" />
              )}
            </ListItemIcon>
            <ListItemText primary={t('signup:lowerCaseLetters')} />
          </ListItem>
          <ListItem sx={{ pl: 8 }}>
            <ListItemIcon>
              {containsUpperCase && (
                <CheckCircleIcon color="success" />
              )}
            </ListItemIcon>
            <ListItemText primary={t('signup:upperCaseLetters')} />
          </ListItem>
          <ListItem sx={{ pl: 8 }}>
            <ListItemIcon>
              {containsNumber && (
                <CheckCircleIcon color="success" />
              )}
            </ListItemIcon>
            <ListItemText primary={t('signup:numbers')} />
          </ListItem>
          <ListItem sx={{ pl: 8 }}>
            <ListItemIcon>
              {containsSpecialCharacter && (
                <CheckCircleIcon color="success" />
              )}
            </ListItemIcon>
            <ListItemText primary={t('signup:specialCharacters')} />
          </ListItem>
        </List>
      )}
    </Fragment>
  );
}

export default PasswordChecker;
