import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Slide, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes, useSearchParams } from "react-router-dom";
import Signup from "./components/pages/Signup/Signup";

import "./App.css";

// styling from Global styles not working
const theme = createTheme({
  palette: {
    primary: {
      main: "#D14900",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Avenir",
      // textTransform: "none",
      // fontSize: 16,
    },
    button: {
      textTransform: "none",
    },
  },

  // FIX: Working?
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: "Avenir",
          fontSize: 20,
          "& .MuiChip-label": {
            // wordWrap: "ellipsis",
            // whiteSpace: "normal",
            // textOverflow: "clip",
            // textAlign: "center",
            fontSize: 16,
            maxWidth: 100,
          },
        },
      },
    },
    menuPaper: {
      backgroundColor: "lightblue",
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
    // MuiTableHead: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#db3131",
    //       // fontFamily: "Avenir",
    //       // fontSize: 20,
    //     },
    //     asterisk: {
    //       color: "#db3131",
    //       "&$error": {
    //         color: "#db3131",
    //       },
    //     },
    //   },
    // },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
});

function App() {

  const [searchParams, setSearchParams] = useSearchParams();
  const plan = searchParams.get("state")

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="*" element={<Signup plan={plan} />} />
        </Routes>
        <ToastContainer
          position="bottom-left"
          transition={Slide}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          limit={4}
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
