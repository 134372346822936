import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SignupForm from "./SignupForm";
import VerifyEmail from "./VerifyEmail";
import SignupImage from "../../../assets/images/signup_image.png"

function Signup({ plan }) {
  const [display, setDisplay] = useState("signupForm");

  const handleAfterSignup = () => {
    setDisplay("verifyEmail");
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {display === "signupForm" && (
          <SignupForm plan={plan} onAfterSignup={handleAfterSignup} />
        )}
        {display === "verifyEmail" && <VerifyEmail />}
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${SignupImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </Grid>
  );
}

export default Signup;
