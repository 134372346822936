import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CustomIcon from "../../utils/CustomIcon";
import axios from "axios";
import PasswordChecker from "./PasswordChecker";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { getCookie } from "../../../utils/cookies";
import { useTranslation } from "react-i18next";

const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const CUSTOM_AUTH0_API_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_MANAGEMENT_API_DOMAIN = process.env.REACT_APP_AUTH0_API_DOMAIN;
const APP_HOST = process.env.REACT_APP_HOST;

function Consent(props) {
  const { t } = useTranslation();

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {t("consent")}{" "}
      <Link color="inherit" href="https://www.bliro.io/terms-and-conditions">
        {t("termsOfService")}
      </Link>{" "}
      {t("and")}{" "}
      <Link color="inherit" href="https://www.bliro.io/privacy-policy">
        {t("privacyPolicy")}
      </Link>{" "}
      {t("consentEmail")}
    </Typography>
  );
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://bliro.io/">
        bliro.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignupForm({ plan, onAfterSignup }) {
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  let [searchParams] = useSearchParams();
  const [celloUcc, setCelloUcc] = useState(undefined);
  const [utm, setUtm] = useState({
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmTerm: null,
    utmContent: null,
  });

  const { t } = useTranslation();

  const handleSetCelloUcc = async () => {
    try {
      const celloUcc = await window.CelloAttribution("getUcc");
      if (celloUcc && typeof celloUcc === "string") {
        setCelloUcc(celloUcc);
      }
    } catch (error) {
      console.error("Error fetching Cello UCC:", error);
    }
  };

  useEffect(() => {
    handleSetCelloUcc();

    if (
      searchParams.has("utm_source") ||
      searchParams.has("utm_medium") ||
      searchParams.has("utm_campaign") ||
      searchParams.has("utm_term") ||
      searchParams.has("utm_content")
    ) {
      const utmSource = searchParams.get("utm_source");
      const utmMedium = searchParams.get("utm_medium");
      const utmCampaign = searchParams.get("utm_campaign");
      const utmTerm = searchParams.get("utm_term");
      const utmContent = searchParams.get("utm_content");
      setUtm({
        utmSource: utmSource === "null" ? null : utmSource,
        utmMedium: utmMedium === "null" ? null : utmMedium,
        utmCampaign: utmCampaign === "null" ? null : utmCampaign,
        utmTerm: utmTerm === "null" ? null : utmTerm,
        utmContent: utmContent === "null" ? null : utmContent,
      });
    } else {
      const utmSource = getCookie("utm_source");
      const utmMedium = getCookie("utm_medium");
      const utmCampaign = getCookie("utm_campaign");
      const utmTerm = getCookie("utm_term");
      const utmContent = getCookie("utmContent");
      setUtm({
        utmSource: utmSource === "null" ? null : utmSource,
        utmMedium: utmMedium === "null" ? null : utmMedium,
        utmCampaign: utmCampaign === "null" ? null : utmCampaign,
        utmTerm: utmTerm === "null" ? null : utmTerm,
        utmContent: utmContent === "null" ? null : utmContent,
      });
    }
  }, [searchParams]);

  const handleProviderSignup = async provider => {
    const state = JSON.stringify({ celloUcc, utm });
    const stateB64 = btoa(state);

    const url = `https://${CUSTOM_AUTH0_API_DOMAIN}/authorize?response_type=token&client_id=${CLIENT_ID}&connection=${provider}&redirect_uri=https%3A%2F%2F${APP_HOST}&state=${stateB64}`;
    window.location.href = url;
  };

  const handleSignup = async event => {
    event.preventDefault();
    if (!isPasswordValid) {
      return;
    }

    const data = new FormData(event.currentTarget);
    const url = `https://${AUTH0_MANAGEMENT_API_DOMAIN}/dbconnections/signup`;
    const email = data.get("email");
    const firstName = data.get("firstName");
    const lastName = data.get("lastName");
    const utmStr = JSON.stringify(utm);
    const utmB64 = btoa(utmStr);

    const name = `${firstName} ${lastName}`;
    try {
      const response = await axios({
        url,
        method: "POST",
        data: {
          client_id: CLIENT_ID,
          connection: "Username-Password-Authentication",
          email: email,
          password: data.get("password"),
          given_name: firstName,
          family_name: lastName,
          name: name,
          user_metadata: {
            celloUcc,
            utm: utmB64,
          },
        },
      });

      console.log(response);

      onAfterSignup();
    } catch (err) {
      console.error(err);
      const message = err.response?.data?.description || err.message;
      notifyError(message);
    }
  };

  const handleIsValid = isValid => {
    setIsPasswordValid(prev => isValid);
  };

  const notifyError = message => {
    toast.error(message);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container flexDirection="column" alignItems="center">
          <Grid item xs={12}>
            <CustomIcon icon="BliroLogoAppIcon" height={80} width={80} />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              {t("signup:signUp")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<CustomIcon icon="GoogleLogo" />}
              onClick={event => handleProviderSignup("google-oauth2")}
            >
              {t("signup:signUpWithGoogle")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<CustomIcon icon="MicrosoftLogo" />}
              onClick={event => handleProviderSignup("Microsoft")}
            >
              {t("signup:signUpWithMicrosoft")}
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ mt: 4 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={5}>
            <hr />
          </Grid>
          <Grid item>{t("signup:or")}</Grid>
          <Grid item xs={5}>
            <hr />
          </Grid>
        </Grid>

        <Box component="form" noValidate onSubmit={handleSignup} sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label={t("signup:firstName")}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label={t("signup:lastName")}
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label={t("signup:businessEmailAddress")}
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label={t("signup:password")}
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={event => {
                  setPassword(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordChecker password={password} onIsValid={handleIsValid} />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("signup:signUpButton")}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                variant="body2"
                onClick={() => {
                  window.location.href = `https://${CUSTOM_AUTH0_API_DOMAIN}/authorize?response_type=token&client_id=${CLIENT_ID}&redirect_uri=https%3A%2F%2F${APP_HOST}`;
                }}
              >
                {t("signup:alreadyHaveAccount")}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Consent sx={{ mt: 5 }} />
      {/* <Copyright sx={{ mt: 1 }} /> */}
    </Container>
  );
}
