import { ReactComponent as BliroLogoAndText } from '../../assets/images/bliro_logo_and_text.svg'
import { ReactComponent as BliroLogoAppIcon } from '../../assets/images/bliro_logo_app_icon.svg'
import { ReactComponent as BliroLogo } from '../../assets/images/bliro_logo.svg'
import { ReactComponent as GoogleLogo } from '../../assets/images/google_logo.svg'
import { ReactComponent as MicrosoftLogo } from '../../assets/images/microsoft_logo.svg'
import SaveIcon from '@mui/icons-material/Save'

function CustomIcon (props) {
  switch (props.icon) {
    case 'BliroLogo':
      return (<BliroLogo height={props.height || 24} width={props.width || 24} />)
    case 'BliroLogoAndText':
      return (<BliroLogoAndText height={props.height || 24} width={props.width || 24} />)
    case 'BliroLogoAppIcon':
      return (<BliroLogoAppIcon height={props.height || 24} width={props.width || 24} />)
    case 'GoogleLogo':
      return (<GoogleLogo height={props.height || 24} width={props.width || 24} />)
    case 'MicrosoftLogo':
      return (<MicrosoftLogo height={props.height || 24} width={props.width || 24} />)
    case 'BritishFlagIcon':
      return (<span aria-label="british-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇬🇧</span>)
    case 'AustralianFlagIcon':
      return (<span aria-label="australian-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇦🇺</span>)
    case 'USFlagIcon':
      return (<span aria-label="us-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇺🇸</span>)
    case 'SpanishFlagIcon':
      return (<span aria-label="spanish-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇪🇸</span>)
    case 'FrenchFlagIcon':
      return (<span aria-label="french-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇫🇷</span>)
    case 'GermanFlagIcon':
      return (<span aria-label="german-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇩🇪</span>)
    case 'ItalianFlagIcon':
      return (<span aria-label="italian-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇮🇹</span>)
    case 'PortugueseFlagIcon':
      return (<span aria-label="portuguese-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇵🇹</span>)
    case 'DutchFlagIcon':
      return (<span aria-label="dutch-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇳🇱</span>)
    case 'HindiFlagIcon':
      return (<span aria-label="hindi-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇮🇳</span>)
    case 'JapaneseFlagIcon':
      return (<span aria-label="japanese-flag-icon" role="img" style={{'fontSize': props.width || props.height || 24}} >🇯🇵</span>)
    default:
      return (<SaveIcon height={props.height || 24} width={props.width || 24} />)
  }
}

export default CustomIcon
