import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomIcon from '../../utils/CustomIcon';
import { useTranslation } from 'react-i18next';

const theme = createTheme();

export default function VerifyEmail() {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Grid container flexDirection='column' alignItems='center'>
            <Grid item xs={12}>
                <CustomIcon icon="BliroLogoAppIcon" height={80} width={80} />
              </Grid>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5">
                  {t('signup:verifyEmail')}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{mt: 8}}>
                <Typography component="h2" variant="h6">
                {t('signup:accountCreationSuccess')}
                </Typography>
              </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}